import React, { useState } from "react"
import { Link } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai"
import LogoIcon from "./logo-icon"
import Language from "./laguage"
import nav from "../styles/nav.module.css"

function Nav() {
  const [navOpen, setNavOpen] = useState(false)
  const classNames = require("classnames")


  return (
    <nav role="navigation">
      <div className={nav.wrapper}>
          <div className={classNames([nav.navSection, nav.left])}>
            <Link to={"#hero"} onClick={() => setNavOpen(false)}>
              <LogoIcon width={"1.8rem"}/>
              <FormattedMessage id="title"/>
            </Link>
          </div>
          <div className={classNames([nav.mobileSection, navOpen ? null : nav.offset])}>
            <div className={classNames([nav.navSection, nav.right])}>

              <Link to={"#about"} onClick={() => setNavOpen(false)}>
                <FormattedMessage id="nav.about"/>
              </Link>
              <Link to={"#technology"} onClick={() => setNavOpen(false)}>
                <FormattedMessage id="nav.technology"/>
              </Link>
              <Link to={"#services"} onClick={() => setNavOpen(false)}>
                <FormattedMessage id="nav.services"/>
              </Link>
              <Link to={"#contact"} onClick={() => setNavOpen(false)}>
                <FormattedMessage id="nav.contact"/>
              </Link>
              <div className={nav.switch} onClick={() => setNavOpen(false)} role={"presentation"}>
                <Language/>
              </div>
            </div>

          </div>
        <div className={nav.mobileIcon}>
          {navOpen ?
            <button onClick={() => setNavOpen(false)}>
              <AiOutlineClose/>
            </button> :
            <button onClick={() => setNavOpen(true)}>
              <AiOutlineMenu/>
            </button>
          }
        </div>
      </div>
    </nav>
  )
}

export default Nav