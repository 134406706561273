import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const LogoIcon = ({width}) => {
  const data = useStaticQuery(graphql`
    query {
      logoIcon: file(relativePath: { eq: "favicon.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.logoIcon.childImageSharp.fluid} style={{height: 'auto', width: width, display: "block"}} imgStyle={{objectFit: 'contain'}} />
}

export default LogoIcon
