import React from "react"

import Nav from "./nav"
import Footer from "./footer"
import Cookies from "./cookies"

import { injectIntl } from "gatsby-plugin-intl"

const Layout = ({ children, intl }) => (
  <>
    <Nav/>
    <main>{children}</main>
    <Footer/>
    <Cookies/>
  </>
)


export default injectIntl(Layout)