import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import footer from "../styles/footer.module.css"
import LogoIcon from "./logo-icon"

const Footer = () => (
  <footer className={footer.section}>
    <div className={footer.wrapper}>
      <div className={footer.logo}>
        <LogoIcon width={"1.8rem"} />
        <FormattedMessage id="title" />
      </div>
    </div>
  </footer>
)

export default Footer
